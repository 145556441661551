// React
import React, { useEffect, useState } from 'react';

// Redux
import { connect } from 'react-redux';
import { withRoomContext } from '../../../../RoomContext';

// Router
import { useHistory } from 'react-router-dom';

// Prop types
import PropTypes from 'prop-types';

// Message
import { FormattedMessage, useIntl } from 'react-intl';

// Mui styles
import { withStyles, MuiThemeProvider, createTheme } from '@material-ui/core/styles';

// Mui core
import { FormControl, Button, Typography, Box, CircularProgress } from '@material-ui/core';

// const
import { DEFAULT_SERVICE_ACCOUNT_KEY } from '../../../../const';

// Firestore
import { firestore, functions } from '../../../../lib/firebase';

// util 
import { convertTicketCode, restoreTicketCode } from '../../../../utils';

const styles = (theme) =>
	({
		root : {
			zIndex          : 99,
			width           : '100%',
			minHeight       : '100%',
			backgroundColor : '#cd2c82'
		},
		titleArea : {
			width          : '100%',
			display        : 'flex',
			flexDirection  : 'column',
			justifyContent : 'flex-start',
			alignItems     : 'center',
			margin         : '20px 0 0 0'
		},
		contentArea : {
			width          : '100%',
			height         : '100%',
			display        : 'flex',
			flexDirection  : 'column',
			justifyContent : 'flex-start',
			alignItems     : 'center'
		},
		ticketInputArea : {
			backgroundColor : '#FFF',
			maxWidth        : '460px',
			width           : 'calc( 100% - 30px )',
			margin          : '20px 15px 0 15px',
			borderRadius    : '15px',
			border          : '1px solid #292929'
		},
		contentTitleArea : {
			width          : '100%',
			display        : 'flex',
			flexDirection  : 'column',
			justifyContent : 'flex-start',
			alignItems     : 'center',
			margin         : '15px 0'
		},
		btnArea : {
			width          : '100%',
			display        : 'flex',
			justifyContent : 'center',
			margin         : '15px 0 15px 0'
		},
		titleText : {
			color      : '#FFF',
			fontWeight : 'bold',
			fontSize   : '1.5rem',
			textAlign  : 'center'
		},
		subTitleText : {
			color      : '#FFF',
			fontWeight : 'bold',
			fontSize   : '1rem',
			textAlign  : 'center'
		},
		contentTitleText : {
			color        : '#292929',
			fontSize     : '1rem',
			fontWeight   : 'bold',
			marginBottom : '3px'
		},
		contentExplanation : {
			color      : '#292929',
			fontSize   : '1rem',
			fontWeight : 'bold'
		},
		contentTitle : {
			marginBottom : '20px'
		},
		serviceLogo : {
			width          : '100%',
			maxWidth       : '500px',
			display        : 'flex',
			justifyContent : 'center'
		},
		serviceLogoImg : {
			width  : '76%',
			height : 'auto'
		},
		ticketLoginSection : {
			display        : 'flex',
			flexDirection  : 'column',
			alignItems     : 'center',
			justifyContent : 'flex-start',
			margin         : '15px 0',
			width          : '100%'
		},
		purchaseMessegeSection : {
			display        : 'flex',
			flexDirection  : 'column',
			alignItems     : 'center',
			justifyContent : 'flex-start',
			margin         : '5px 0',
			width          : '100%'
		},
		purchaseMessege : {
			color    : '#FFF',
			fontSize : '0.7rem'
		},
		purchaseTicketSection : {
			display        : 'flex',
			flexDirection  : 'column',
			alignItems     : 'center',
			justifyContent : 'flex-start',
			margin         : '15px 0 0 0',
			width          : '100%'
		},
		purchaseTicketText : {
			fontSize  : '0.8rem',
			color     : '#FFF',
			width     : '100%',
			textAlign : 'center'
		},
		btnGroup : {
			width      : '90%',
			marginLeft : '10%',
			marginTop  : '8px'
		},
		explanationBox :
		{
			marginBottom : '25px'
		},
		explanationText :
		{
			color    : '#999898',
			fontSize : '0.8rem'
		},
		contentText :
		{
			color    : '#292929',
			fontSize : '1rem'
		},
		contentTextSmall :
		{
			color    : '#292929',
			fontSize : '0.8rem'
		},
		freeCondText : {
			color           : '#292929',
			fontSize        : '1.1rem',
			padding         : '5px 15px',
			backgroundColor : '#F4DA23',
			borderRadius    : '3px'
		},
		ticketCodeTitle : {
			color      : '#292929',
			fontSize   : '1.1rem',
			fontWeight : 'bold'
		},
		ticketCodeText : {
			color      : '#cd2c82',
			fontSize   : '2rem',
			fontWeight : 'bold'
		},
		inputText : {
			background   : '#FFF',
			padding      : '0.6rem 0.6rem',
			borderRadius : '0.2rem',
			color        : '#292929',
			textAlign    : 'left',
			border       : '1px solid #292929',
			fontSize     : '16px',
			'&:focus'    : {
				outline : 'none'
			},
			'&:disabled' : {
				color : '#F1F1F1'
			},
			'&::-ms-reveal' : {
				display : 'none'
			}
		},
		inputTextTicket : {
			width            : '70%',
			maxWidth         : '300px',
			'&::placeholder' : {
				textAlign  : 'center',
				fontSize   : '1rem',
				color      : '#AAA',
				fontFamily : '"Hiragino Kaku Gothic Pro","Hiragino Kaku Gothic ProN","Hiragino Sans","Meiryo",Arial,sans-serif'
			}
		},
		inputTextEmail : {
			width    : '95%',
			maxWidth : '330px'
		},
		policyLine : {
			width          : '100%',
			maxWidth       : '350px',
			padding        : '0 10px',
			margin         : '0 0 10px 0',
			display        : 'flex',
			justifyContent : 'space-between'
		},
		linkText : {
			fontSize       : '0.7rem',
			textDecoration : 'underline',
			color          : 'white'
		},
		supportLine : {
			maxWidth       : '350px',
			width          : '100%',
			margin         : '30px 0 0 0',
			padding        : '0 10px',
			display        : 'flex',
			justifyContent : 'center'
		},
		visibilityButton : {
			position  : 'absolute',
			top       : '0',
			bottom    : '16px',
			right     : '-6px',
			color     : '#FFF',
			'&:hover' : {
				backgroundColor : 'transparent'
			}
		},
		circle : {
			width           : '4rem',
			height          : '4rem',
			borderRadius    : '4rem',
			backgroundColor : '#999898',
			display         : 'flex',
			justifyContent  : 'center',
			alignItems      : 'center',
			margin          : '0.5rem 0'
		},
		button : {
			padding      : '2px 10px',
			borderRadius : '10px',
			fontSize     : '1.1rem',
			boxShadow    : '2px 2px 4px #aaa'
		},
		submitButton : {
			backgroundColor : '#cd2c82',
			color           : 'white',
			width           : '50%',
			maxWidth        : '250px',
			'&:hover'       : {
				backgroundColor : '#D9388E'
			}
		},
		freeTicketButton : {
			backgroundColor : '#F4DA23',
			color           : '#292929',
			width           : '80%',
			maxWidth        : '350px',
			'&:hover'       : {
				backgroundColor : '#D9C31E'
			}
		},
		purchaseButton : {
			backgroundColor : '#FFF',
			color           : '#292929',
			width           : '80%',
			maxWidth        : '350px',
			'&:hover'       : {
				backgroundColor : '#E8E8E8'
			}
		},
		forgotPasswordLabel :
		{
			fontSize     : '0.6rem',
			color        : '#999898',
			textAlign    : 'center',
			marginBottom : '16px'
		},
		link :
		{
			color  : 'var(--submit-button-color)',
			cursor : 'pointer'
		},
		loginHeaderSection :
		{
			paddingTop     : '10px',
			position       : 'relative',
			width          : '100%',
			maxWidth       : '550px',
			height         : '40px',
			display        : 'flex',
			justifyContent : 'space-between'
		},
		headerTitleArea : {
			display         : 'flex',
			justifyContent  : 'center',
			alignItems      : 'center',
			backgroundColor : '#FFF',
			padding         : '0 10px',
			height          : '24px',
			marginLeft      : '14px'
		},
		headerTitleText : {
			padding    : '0',
			color      : '#cd2c82',
			fontWeight : 'bold'
		},
		languageSelectBox : {
			position                       : 'absolute !important',
			marginTop                      : 'auto',
			marginBottom                   : 'auto',
			top                            : '0',
			bottom                         : '0',
			right                          : '0',
			height                         : '100%',
			display                        : 'flex',
			alignItems                     : 'center',
			justifyContent                 : 'center',
			[theme.breakpoints.down('sm')] :
			{
				right          : '1px',
				justifyContent : 'end'
			}
		},
		formControl : {
			minWidth       : 90,
			display        : 'flex',
			justifyContent : 'end'
		},
		langSelect : {
			backgroundColor : 'rgba(255,255,255, 0)',
			height          : '25px',
			fontSize        : '0.8rem',
			borderRadius    : '10px',
			border          : '1px solid #292929',
			color           : '#292929',
			padding         : '0 0 0 10px',
			width           : '80px'
		},
		langSelectColored : {
			border     : '1px solid #FFF !important',
			color      : '#FFF !important',
			'& option' : {
				backgroundColor : '#cd2c82 !important'
			}
		},
		logoImageBox : {
			marginTop      : '20px',
			width          : '100%',
			height         : '80px',
			display        : 'flex',
			alignItems     : 'center',
			justifyContent : 'center',
			padding        : '5px 0',
			'&> img'       : {
				objectFit : 'contain'
			}
		},
		logoImage : {
			height : '80px',
			width  : 'auto'
		},
		loadingWrapper :
		{
			width           : '100%',
			height          : '250px',
			display         : 'flex',
			justifyContent  : 'center',
			alignItems      : 'flex-end',
			backgroundColor : 'rgba(255, 255, 255, 0)'
		},
		loadingIndicator :
		{
			color : '#FFF'
		},
		errorMsg : {
			width        : '100%',
			color        : 'red',
			fontSize     : '0.8rem',
			textAlign    : 'center',
			marginBottom : '10px'
		}
	});

const theme = createTheme({
	typography : {
		fontFamily : '"Hiragino Kaku Gothic Pro","Hiragino Kaku Gothic ProN","Hiragino Sans","Meiryo",Arial,sans-serif'
	},
	overrides : {
		MuiBackdrop : {
			root : {
				backgroundColor : 'rgba(25,25,25,0.95)'
			}
		},
		MuiDialogTitle : {
			root : {
				backgroundColor : '#fff',
				color           : '#999898',
				width           : '100%'
			}
		},
		MuiDialogContent : {
			root : {
				justifyContent : 'center',
				display        : 'flex',
				flexDirection  : 'column',
				alignItems     : 'center',
				overflowY      : 'none',
				padding        : '0.5rem 0'
			}
		},
		MuiDialogActions : {
			root : {
				justifyContent : 'space-around'
			}
		},
		MuiPaper : {
			rounded : {
				borderRadius : '0.5rem'
			}
		},
		MuiOutlinedInput : {
			input : {
				'&::-ms-reveal' : {
					display : 'none'
				}
			}
		}
	}
});

const ServiceGuestLogin = ({
	classes,
	logoImageUrl,
	setServiceGuestLoggedIn,
	setServiceGuest,
	setServiceTicketLogin,
	serviceParamKey,
	setFree,
	backFromCall,
	issueFreeTicket,
	intlObj,
	setLocale
}) =>
{
	const intl = useIntl();
	const history = useHistory();

	const prevLoginCode = localStorage.getItem('loginTicketCode');

	const [ errorMsg, setErrorMsg ] = useState('');

	const [ ticketNumber, setTicketNumber ] = useState(prevLoginCode && localStorage.getItem('freeTicketLogin') !== 'true' ? restoreTicketCode(prevLoginCode) : '');

	const [ loading, setLoading ] = useState(false);

	const handleClose = async () =>
	{
		setServiceGuestLoggedIn(true);
		setErrorMsg('');
		setTicketNumber('');
		setLoading(false);
	};

	const updateTicketNumber = (e) =>
	{
		setTicketNumber(e.target.value);
	};

	const moveToFreeTicketPage = () =>
	{
		let serviceParamKeyValue = serviceParamKey;

		if (!serviceParamKeyValue)
		{
			serviceParamKeyValue=DEFAULT_SERVICE_ACCOUNT_KEY;
		}

		history.push({
			pathname : '/free',
			search   : `?id=${serviceParamKeyValue}`
		});
	};

	const moveToPurchasePage = () =>
	{
		let serviceParamKeyValue = serviceParamKey;

		if (!serviceParamKeyValue)
		{
			serviceParamKeyValue=DEFAULT_SERVICE_ACCOUNT_KEY;
		}

		history.push({
			pathname : '/purchase',
			search   : `?id=${serviceParamKeyValue}`
		});
	};

	const handleLoginByTicket = async () =>
	{
		setErrorMsg('');
		setLoading(true);

		// 前回ログイン時のチケットコードを最初から入力しない
		localStorage.removeItem('loginTicketCode');

		if (!ticketNumber || ticketNumber.length !== 14)
		{
			setLoading(false);
			setErrorMsg([ intl.formatMessage({
				id             : 'error.incorrectTicket',
				defaultMessage : 'Incorrect ticket code'
			}) ]);
		}
		else
		{
			try
			{
				const loginByTicketAPI = functions.httpsCallable('serviceGuestLoginByTicket');

				// first 4 letters
				const ticketParamKey = ticketNumber.slice(0, 4);

				// from 5 to end
				const ticketNumberWithoutIdentifier = ticketNumber.slice(4);

				try
				{
					loginByTicketAPI({
						ticketParamKey : ticketParamKey,
						ticketNumber   : ticketNumberWithoutIdentifier
					}).then((response) =>
					{
						const data = response.data;

						if (data.success)
						{
							const remainingSeconds = data.duration - data.consumedSeconds;

							if (!data.deactivated && (remainingSeconds > 0 || data.duration === -1))
							{
								setServiceGuest({});

								if (data.free)
								{
									setFree(true);
									localStorage.setItem('freeTicketLogin', 'true');
								}
								else
								{
									setFree(false);
									localStorage.removeItem('freeTicketLogin');
								}

								localStorage.setItem('loginTicketCode', convertTicketCode(ticketNumber));

								let expiryTimestamp;

								if (data.expiryTerm.value === 0)
								{
									expiryTimestamp = 0;
								}
								else
								{
									expiryTimestamp = data.expiryTimestamp;
								}

								setServiceTicketLogin({
									login            : true,
									ticketId         : data.ticketId,
									ticketParamKey   : ticketParamKey,
									ticketNumberHint : data.numberHint,
									expiryTimestamp  : expiryTimestamp,
									duration         : data.duration,
									consumedSeconds  : data.consumedSeconds
								});
								handleClose();
							}
							else
							{
								setLoading(false);
								setErrorMsg([ intl.formatMessage({
									id             : 'error.timeUp',
									defaultMessage : 'No talk time left'
								}) ]);
							}
						}
						else
						{

							if (data.reason === 'dateExpiry')
							{
								setErrorMsg([ intl.formatMessage({
									id             : 'error.expiredTicket',
									defaultMessage : 'Ticket has expired'
								}) ]);
							}
							else if (data.reason === 'disabled')
							{
								setErrorMsg([ intl.formatMessage({
									id             : 'error.invalidTicket',
									defaultMessage : 'Ticket is invalid'
								}) ]);
							}
							else if (data.reason === 'usedFreeTicket')
							{
								setErrorMsg([ intl.formatMessage({
									id             : 'error.timeUp',
									defaultMessage : 'No talk time left'
								}) ]);
							}
							else
							{
								setErrorMsg([ intl.formatMessage({
									id             : 'error.certificationFailed',
									defaultMessage : 'Authentication failed'
								}),
								intl.formatMessage({
									id             : 'error.checkTicket',
									defaultMessage : 'Please check your ticket code'
								})
								]);
							}
							setLoading(false);
						}
					})
						.catch(() =>
						{
							setLoading(false);
							setErrorMsg([ intl.formatMessage({
								id             : 'error.certificationFailed',
								defaultMessage : 'Authentication failed'
							}),
							intl.formatMessage({
								id             : 'error.checkTicket',
								defaultMessage : 'Please check your ticket code'
							})
							]);
						});
				}
				catch
				{
					setLoading(false);
					setErrorMsg([ intl.formatMessage({
						id             : 'error.certificationFailed',
						defaultMessage : 'Authentication failed'
					}),
					intl.formatMessage({
						id             : 'error.checkTicket',
						defaultMessage : 'Please check your ticket code'
					})
					]);
				}
			}
			catch
			{
				setErrorMsg([ intl.formatMessage({
					id             : 'error.certificationFailed',
					defaultMessage : 'Authentication failed'
				}),
				intl.formatMessage({
					id             : 'error.checkTicket',
					defaultMessage : 'Please check your ticket code'
				})
				]);
				setLoading(false);
			}
		}
	};

	useEffect(() =>
	{
		if (backFromCall)
		{
			const serviceConnectionId = localStorage.getItem('serviceConnectionId');

			if (serviceConnectionId)
			{
				setLoading(true);

				firestore.collection('serviceConnections')
					.doc(serviceConnectionId)
					.get()
					.then((response) =>
					{
						const connectionData = response.data();

						const serviceTicketId = connectionData.serviceTicketId;

						firestore.collection('serviceTickets')
							.doc(serviceTicketId)
							.onSnapshot((querySnapshot) =>
							{
								const ticketData = querySnapshot.data();

								setServiceTicketLogin({
									login            : true,
									ticketId         : serviceTicketId,
									ticketParamKey   : ticketData.accountParamKey,
									ticketNumberHint : ticketData.numberHint,
									expiryTimestamp  : ticketData.expiryTimestamp,
									duration         : ticketData.duration,
									consumedSeconds  : ticketData.consumedSeconds
								});

								// 再読み込みした時にログインを継続し、freeのステータスも継続する
								// そのためにlocalstorageを利用して判定
								if (localStorage.getItem('freeTicketLogin') === 'true')
								{
									setFree(true);
								}

								handleClose();
							})
							.catch(() =>
							{
								setLoading(false);
							});

					})
					.catch(() =>
					{
						setLoading(false);
					});
			}
		}
	// eslint-disable-next-line 
	}, [ backFromCall ]);

	return (
		<MuiThemeProvider theme={theme}>
			<Box className={classes.root}>
				<Box className={classes.content}>
					<Box className={classes.contentArea}>
						{ loading ?

							<Box className={classes.loadingWrapper}>
								<CircularProgress className={classes.loadingIndicator}/>
							</Box>
							:
							<>
								<Box className={classes.loginHeaderSection}>
									<Box className={classes.headerTitleArea}>
										<Typography className={classes.headerTitleText}>
											<FormattedMessage
												id='service0.serviceName'
												defaultMessage='LIVE interpretation ticket'
											/>
										</Typography>
									</Box>
									<Box className={classes.languageSelectBox}>
										<FormControl variant='outlined' className={classes.formControl}>
											<select
												value={intlObj.locale}
												onChange={(e) => setLocale(e.target.value)}
												label='Lang'
												className={`${classes.langSelect} ${classes.langSelectColored}`}
											>
												<option value={'ja'}>JP</option>
												<option value={'en'}>EN</option>
												<option value={'zn'}>CN(SC)</option>
												<option value={'zn-tw'}>CN(TC)</option>
												<option value={'ko'}>KR</option>
											</select>
										</FormControl>
									</Box>
								</Box>
								<Box className={classes.titleArea}>
									<Typography className={classes.subTitleText}>
										<FormattedMessage
											id='service0.serviceDescription1'
											defaultMessage='call an interpreter  with one touch'
										/>
									</Typography>
									<Typography className={classes.titleText}>
										<FormattedMessage
											id='service0.serviceDescription2'
											defaultMessage='LIVE INTERPRETATION ONLINE'
										/>
									</Typography>
								</Box>

								<Box className={classes.serviceLogo}>
									<img
										className={classes.serviceLogoImg}
										alt='service logo'
										src='images/service_logo.svg'
									/>
								</Box>

								<Box className={classes.ticketInputArea}>
									<Box className={classes.contentTitleArea}>
										<Typography className={classes.contentTitleText}>
											<FormattedMessage
												id='service0.usage'
												defaultMessage='Usage'
											/>
										</Typography>
										<Box>
											<Typography className={classes.contentExplanation}>
												<FormattedMessage
													id='service0.usage2'
													defaultMessage='①　Buy interpreter ticket'
												/>
											</Typography>
											<Typography className={classes.contentExplanation}>
												<FormattedMessage
													id='service0.usage3'
													defaultMessage='②　Enter the ticket code issued'
												/>
											</Typography>
										</Box>
									</Box>

									{errorMsg &&
									<p className={classes.errorMsg}>{errorMsg}</p>
									}

									<Box className={classes.ticketLoginSection}>
										<input
											value={ticketNumber}
											className={`${classes.inputText} ${classes.inputTextTicket}`}
											onChange={(e) => updateTicketNumber(e)}
											type='text'
											placeholder={intl.formatMessage({
												id             : 'service0.enterTicketCode',
												defaultMessage : 'Enter interpretation ticket code'
											})}
										/>
										<Box className={classes.btnArea}>
											<Button
												className={`${classes.button} ${classes.submitButton}`}
												onClick={handleLoginByTicket}
												color='primary'
											>
												<FormattedMessage
													id='service0.startUsingButton'
													defaultMessage='START'
												/>
											</Button>
										</Box>
									</Box>
								</Box>

								<Box className={classes.purchaseTicketSection}>
									<Typography className={classes.purchaseTicketText}>
										<FormattedMessage
											id='service.descriptionBuyTicket'
											defaultMessage='Purchase Ticket Here'
										/>
									</Typography>

									<Button
										className={`${classes.button} ${classes.purchaseButton}`}
										onClick={moveToPurchasePage}
										color='primary'
									>
										<FormattedMessage
											id='service.buyTicketButton'
											defaultMessage='Purchase Ticket'
										/>
									</Button>
								</Box>

								{ issueFreeTicket &&
								<Box className={classes.purchaseTicketSection}>
									<Typography className={classes.purchaseTicketText}>
										<FormattedMessage
											id='service0.descriptionFreeTicket'
											defaultMessage='Up to 5 minutes free'
										/>
									</Typography>

									<Button
										className={`${classes.button} ${classes.freeTicketButton}`}
										onClick={moveToFreeTicketPage}
										color='primary'
									>
										<FormattedMessage
											id='service0.freeTicketButton'
											defaultMessage='USE TRIAL TICKET'
										/>
									</Button>
								</Box>
								}

								{ logoImageUrl &&
									<Box className={classes.logoImageBox}>
										<img
											alt='logo'
											src={logoImageUrl}
											className={classes.logoImage}
										/>
									</Box>
								}
								<Box className={classes.supportLine}>
									<Typography>
										<a
											className={classes.linkText}
											href={'https://www.tuuyaku.com/supportcontact'}
											target='_blank' rel='noreferrer'
										>
											<FormattedMessage
												id='service0.support'
												defaultMessage='support center'
											/>
										</a>
									</Typography>
								</Box>
								<Box className={classes.policyLine}>
									<Typography>
										<a
											className={classes.linkText}
											href={'https://www.tuuyaku.com/specific-trade-law'}
											target='_blank' rel='noreferrer'
										>
											<FormattedMessage
												id='service0.law'
												defaultMessage='specified commercial transaction act'
											/>
										</a>
									</Typography>
									<Typography>
										<a
											className={classes.linkText}
											href={'https://www.tuuyaku.com/privacy'}
											target='_blank' rel='noreferrer'
										>
											<FormattedMessage
												id='service0.privacy'
												defaultMessage='privacy policy'
											/>
										</a>
									</Typography>
								</Box>
							</>
						}
					</Box>
				</Box>
			</Box>
		</MuiThemeProvider>
	);
};

ServiceGuestLogin.propTypes =
{
	classes                 : PropTypes.object.isRequired,
	setServiceGuestLoggedIn : PropTypes.func.isRequired,
	logoImageUrl            : PropTypes.string,
	setServiceGuest         : PropTypes.func.isRequired,
	setServiceTicketLogin   : PropTypes.func.isRequired,
	serviceParamKey         : PropTypes.string,
	setFree                 : PropTypes.func.isRequired,
	backFromCall            : PropTypes.bool.isRequired,
	issueFreeTicket         : PropTypes.bool.isRequired,
	intlObj                 : PropTypes.object.isRequired,
	setLocale               : PropTypes.func.isRequired
};

const makeMapStateToProps = () =>
{
	const mapStateToProps = () =>
	{
		return {
		};
	};

	return mapStateToProps;
};

const mapDispatchToProps = () =>
{
	return {
	};
};

export default withRoomContext(connect(
	makeMapStateToProps,
	mapDispatchToProps,
	null,
	{
	}
)(withStyles(styles)(ServiceGuestLogin)));