import React, { useState, useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withStyles, createTheme, MuiThemeProvider } from '@material-ui/core/styles';
import { withRoomContext } from '../../../../RoomContext';

import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';

// util 
import { getDomainWithoutSubdomain, showTaxIncludedAmount } from '../../../../utils';

import { functions } from '../../../../lib/firebase';

// const
import { ISSUED_AT_ADMIN_PAGE, PAYMENT_MONTHLY } from '../../../../const';

// dayjs
import dayjs from 'dayjs';

// dayjsの タイムゾーンの設定
dayjs.extend(require('dayjs/plugin/timezone'));
dayjs.extend(require('dayjs/plugin/utc'));
dayjs.tz.setDefault('Asia/Tokyo');

const drawerWidth = 250;

const styles = (theme) =>
	({
		root :
		{
			width          : '100%',
			height         : '100%',
			minWidth       : '300px',
			position       : 'relative',
			padding        : '15px 20px',
			color          : 'var(--text-color)',
			overflowX      : 'auto',
			display        : 'flex',
			flexDirection  : 'column',
			justifyContent : 'flex-start',
			alignItems     : 'center'
		},
		pageHeader : {
			width          : '100%',
			display        : 'flex',
			justifyContent : 'center'
		},
		title : {
			margin     : '25px 0',
			fontSize   : '1.2rem',
			fontWeight : 'bold',
			color      : '#292929'
		},
		content : {
			maxWidth : '1100px',
			minWidth : '300px',
			width    : '100%'
		},
		spaceLine : {
			width          : '100%',
			display        : 'flex',
			justifyContent : 'space-between',
			margin         : '10px 5px'
		},
		spaceLineEmail : {
			width          : '100%',
			display        : 'flex',
			justifyContent : 'space-between',
			margin         : '10px 5px'
		},
		spaceLineEmailSplit : {
			width          : '100%',
			display        : 'flex',
			flexDirection  : 'column',
			justifyContent : 'flex-start',
			alignItems     : 'start',
			margin         : '10px 5px'
		},
		inputTitle : {
			width      : '40%',
			color      : '#292929',
			fontSize   : '0.9rem',
			height     : '38px',
			lineHeight : '38px'
		},
		inputTitleSplit : {
			width      : '100%',
			minWidth   : '125px',
			color      : '#292929',
			fontSize   : '0.9rem',
			height     : '38px',
			lineHeight : '38px'
		},
		firstEmail : {
			marginBottom : '30px'
		},
		emailInputBox : {
			display        : 'flex',
			justifyContent : 'center',
			width          : '60%'
		},
		emailInputBoxSplit : {
			display        : 'flex',
			justifyContent : 'center',
			width          : '100%'
		},
		additionalEmailInputBox : {
			display        : 'flex',
			justifyContent : 'space-between',
			width          : '60%'
		},
		additionalEmailInputBoxSplit : {
			display        : 'flex',
			justifyContent : 'space-between',
			width          : '100%'
		},
		emailInput : {
			width        : '100%',
			minWidth     : '200px',
			maxWidth     : '900px',
			border       : '1px solid #292929',
			borderRadius : '8px',
			padding      : '10px 10px',
			'&:disabled' : {
				backgroundColor : '#e2e2e2'
			}
		},
		additionalEmailInput : {
			width    : '48% !important',
			minWidth : '95px !important',
			maxWidth : '450px !important'
		},
		ticketTitleLine : {
			width          : '100%',
			display        : 'flex',
			justifyContent : 'space-between',
			margin         : '40px 5px 10px 5px'
		},
		singleLineTickets : {
			width          : '100%',
			display        : 'flex',
			justifyContent : 'space-between',
			maxWidth       : '1100px'
		},
		ticketColumn : {
			marginTop : '10px',
			width     : '100%',
			maxWidth  : '550px'
		},
		ticketColumnConfirm : {
			marginTop : '10px',
			width     : '100%',
			maxWidth  : '1100px'
		},
		ticketBox : {
			display        : 'flex',
			flexDirection  : 'column',
			justifyContent : 'start',
			alignItems     : 'flex-start',
			margin         : '0 5px 15px 5px',
			border         : '1px solid #292929',
			borderRadius   : '10px'
		},
		ticketBoxTwo : {
			width    : '98%',
			minWidth : '380px'
		},
		ticketBoxOne : {
			width                        : '100%',
			minWidth                     : '380px',
			maxWidth                     : '680px',
			[theme.breakpoints.up('md')] : {
				width : `calc( 100% - ${drawerWidth})`
			}
		},
		ticketBoxConfirm : {
			width : '100%'
		},
		ticketBoxTitle : {
			marginLeft : '5px',
			color      : '#cd2c82',
			fontSize   : '0.9rem'
		},
		ticketLine : {
			width                : '100%',
			display              : 'flex',
			justifyContent       : 'space-between',
			'&:last-child > div' : {
				borderBottom : 'none !important'
			},
			'&:first-child' : {
				backgroundColor      : '#cd2c82',
				borderTopLeftRadius  : '9px',
				borderTopRightRadius : '9px'
			},
			'&:first-child > div' : {
				color : '#FFF'
			}
		},
		ticketLineConfirm : {
			width    : '100%',
			maxWidth : '1100px'
		},
		ticketItem : {
			display        : 'flex',
			justifyContent : 'center',
			padding        : '5px 10px',
			color          : '#292929',
			borderRight    : '1px solid #292929',
			borderBottom   : '1px solid #292929',
			fontSize       : '0.9rem'
		},
		ticketItemText : {
			fontSize : '1rem',
			color    : '#292929'
		},
		ticketItemMinutes : {
			width    : '42%',
			minWidth : '90px'
		},
		ticketItemMinutesAfterUse : {
			width    : '19%',
			minWidth : '75px'
		},
		ticketItemExpiry : {
			width     : '30%',
			minWidth  : '101px',
			textAlign : 'center'
		},
		ticketItemAmount : {
			width    : '42%',
			minWidth : '129px'
		},
		ticketItemAmountAfterUse : {
			width    : '35%',
			minWidth : '129px'
		},
		ticketItemQuantity : {
			width          : '16%',
			minWidth       : '75px',
			display        : 'flex',
			flexDirection  : 'column',
			justifyContent : 'center',
			alignItems     : 'center',
			borderBottom   : '1px solid #292929'
		},
		ticketItemMinutesConfirm : {
			width : '17%'
		},
		ticketItemAmountConfirm : {
			width : '25%'
		},
		ticketItemValidTimeConfirm : {
			width : '25%'
		},
		ticketItemQuantityConfirm : {
			width : '8%'
		},
		ticketItemTotalAmountConfirm : {
			width          : '27.5%',
			display        : 'flex',
			justifyContent : 'center',
			borderBottom   : '1px solid #292929',
			borderRight    : 'none !important'
		},
		ticketQuantityInput : {
			width        : '60px',
			borderRadius : '8px',
			padding      : '2px 8px',
			fontSize     : '0.9rem',
			border       : '1px solid #292929',
			'&:disabled' : {
				border : 'none'
			}
		},
		totalAmountLine : {
			height       : '25px !important',
			marginBottom : '0px !important',
			margin       : '30px 0 0 0'
		},
		totalAmountBox : {
			width          : '60%',
			minWidth       : '125px',
			height         : '1.4rem',
			display        : 'flex',
			justifyContent : 'space-between',
			borderBottom   : '1px Solid #292929'
		},
		totalAmountTitle : {
			color      : '#292929',
			fontSize   : '1rem',
			fontWeight : 'bold'
		},
		totalAmountNumber : {
			color      : '#292929',
			fontSize   : '1rem',
			lineHeight : '1.1rem'
		},
		totalAmountPayment : {
			color    : '#292929',
			fontSize : '0.9rem'
		},
		purchasedItemsTable : {
			margin : '20px 0'
		},
		codeCell : {
			width : '34%'
		},
		expiryCell : {
			width : '26%'
		},
		timeCell : {
			width : '18.5%'
		},
		priceCell : {
			width : '21.5%'
		},
		paymentLine : {
			width          : '100%',
			display        : 'flex',
			justifyContent : 'end',
			margin         : '0px 5px 10px 5px'
		},
		linkToServiceBox : {
			width          : '100%',
			display        : 'flex',
			flexDirection  : 'column',
			justifyContent : 'start',
			alignItems     : 'center',
			marginTop      : '30px'
		},
		linkToServiceTitle : {
			margin    : '20px 0 10px 0',
			color     : '#292929',
			width     : '100%',
			textAlign : 'center'
		},
		linkToServiceURL : {
			cursor   : 'pointer',
			fontSize : '0.9rem'
		},
		btnBox : {
			width          : '100%',
			margin         : '50px 0 0 0',
			display        : 'flex',
			justifyContent : 'center'
		},
		btnBoxConfirm : {
			marginTop : '30px !important'
		},
		btn : {
			padding : '6px 55px'
		},
		backBtn : {
			backgroundColor : '#FFF',
			color           : '#292929',
			border          : '1px solid #292929',
			marginRight     : '60px',
			'&:hover'       :
			{
				backgroundColor : '#DDD'
			}
		},
		actionBtn : {
			backgroundColor : '#343532',
			color           : '#FFF',
			'&:hover'       :
			{
				backgroundColor : '#484A46'
			}
		},
		confirmBtnBox : {
			position : 'relative'
		},
		confirmBtnText : {
			position  : 'absolute',
			fontSize  : '0.7rem',
			margin    : 'auto',
			left      : '0',
			right     : '0',
			bottom    : '-20px',
			color     : '#292929',
			wordBreak : 'keep-all',
			textAlign : 'center'
		},
		errorMsg : {
			width        : '100%',
			height       : '1.2rem',
			color        : 'red',
			marginBottom : '8px',
			fontSize     : '0.8rem',
			textAlign    : 'center'
		},
		loading : {
			width           : '100vw',
			height          : '100vh',
			zIndex          : 9999999,
			position        : 'fixed',
			top             : 0,
			left            : 0,
			display         : 'flex',
			justifyContent  : 'center',
			alignItems      : 'center',
			backgroundColor : 'rgba(255, 255, 255, 0.5)'
		}
	});

const theme = createTheme({
	typography : {
		fontFamily : '"Hiragino Kaku Gothic Pro","Hiragino Kaku Gothic ProN","Hiragino Sans","Meiryo",Arial,sans-serif'
	}
});

const today = new Date();

const thisYear = today.getFullYear();

const thisMonth = today.getMonth()+1;

let nextMonth= thisMonth +1;

let nextMonthYear = thisYear;

if (nextMonth > 12)
{
	nextMonth = 1;
	nextMonthYear = thisYear + 1;
}

const showExpiryDays = (term) =>
{
	if (term.value === 0)
	{
		return '-';
	}
	else if (term.type === 'year')
	{
		if (term.value === 1)
		{
			return '1YEAR';
		}
		else
		{
			return `${term.value}YEARS`;
		}
	}
	else if (term.type === 'month')
	{
		if (term.value === 1)
		{
			return '1MONTH';
		}
		else
		{
			return `${term.value}MONTHS`;
		}
	}
	else if (term.value === 7) // type === 'day'
	{
		return '1WEEK';
	}
	else if (term.value === 1)
	{
		return '1DAY';
	}
	else
	{
		return `${term.value}DAYS`;
	}
};

const showExpiryTerm = (term) =>
{

	if (!term)
	{
		return '';
	}
	else if (Number(term.value) === 0)
	{
		return '無期限';
	}
	else
	{
		return `${term.value}${term.type === 'year' ? '年' :term.type === 'month' ? 'ヶ月' : '日'}`;
	}
};

const ticketsBreaklineWidth = 1100;

const inputBreaklineWidth = 800;

const PurchaseTicket = ({
	classes,
	serviceAccount,
	priceLists,
	page,
	fetchTickets,
	drawerWidthProp,
	siteAdminLogin
}) =>
{

	const [ content, setContent ] = useState('home');

	const [ loading, setLoaging ] = useState(false);

	const [ errorMsg, setErrorMsg ] = useState('');

	const [ ticketsList, setTicketsList ] = useState([]);

	const [ ticketsListTerm, setTicketsListTerm ] = useState([]);

	const [ ticketsListExpireAfterUse, setTicketsListExpireAfterUse ] = useState([]);

	const [ ticketsListLen, setTicketsListLen ] = useState(0);

	const [ ticketsListAfterUseLen, setTicketsListAfterUseLen ] = useState(0);

	const [ ticketsListTermLen, setTicketsListTermLen ] = useState(0);

	const [ totalAmount, setTotalAmount ] = useState(null);

	const [ createdTicketsTime, setCreatedTicketsTime ] = useState([]);
	const [ createdTicketsAfterUse, setCreatedTicketsAfterUse ] = useState([]);
	const [ createdTicketsTerm, setCreatedTicketsTerm ] = useState([]);

	const [ email, setEmail ] = useState('');

	const [ additionalEmail1, setAdditionalEmail1 ] = useState('');

	const [ additionalEmail2, setAdditionalEmail2 ] = useState('');

	const [ additionalEmail1Confirm, setAdditionalEmail1Confirm ] = useState('');

	const [ additionalEmail2Confirm, setAdditionalEmail2Confirm ] = useState('');

	const [ serviceUrl, setServiceUrl ] = useState('');

	const [ singleLineTickets, setSingleLineTickets ] = useState(
		window.matchMedia(`(min-width: ${ticketsBreaklineWidth+drawerWidthProp}px)`).matches
	);

	const [ singleLineEmailInput, setSingleLineEmailInput ] = useState(
		window.matchMedia(`(min-width: ${inputBreaklineWidth+drawerWidthProp}px)`).matches
	);

	useEffect(() =>
	{
		window
			.matchMedia(`(min-width: ${ticketsBreaklineWidth+drawerWidthProp}px)`)
			.addEventListener('change', (e) => setSingleLineTickets(e.matches));

		window
			.matchMedia(`(min-width: ${inputBreaklineWidth+drawerWidthProp}px)`)
			.addEventListener('change', (e) => setSingleLineEmailInput(e.matches));
	// eslint-disable-next-line 
	}, []);

	useEffect(() =>
	{
		if (page === 'purchase')
		{

			if (priceLists.priceList)
			{
				const priceListAr = [ ...priceLists.priceList ];

				priceListAr.forEach((item) =>
				{
					item.quantity = 0;
					item.selected = false;
				});

				setTicketsList(priceListAr);
			}

			if (priceLists.priceListExpireAfterUse)
			{
				const priceListAr = [ ...priceLists.priceListExpireAfterUse ];

				priceListAr.forEach((item) =>
				{
					item.quantity = 0;
					item.selected = false;
				});

				setTicketsListExpireAfterUse(priceListAr);
			}

			if (priceLists.priceListTerm)
			{
				const priceListAr = [ ...priceLists.priceListTerm ];

				priceListAr.forEach((item) =>
				{
					item.quantity = 0;
					item.selected = false;
				});

				setTicketsListTerm(priceListAr);
			}

			setEmail(serviceAccount.email);
		}
	}, [ serviceAccount, page, priceLists ]);

	// const closePageHandler = () =>
	// {
	// 	setContent('home');
	// 	setErrorMsg('');
	// 	setLoaging(false);
	// 	setTotalAmount(null);
	// };

	const handleChangeTicketQuantity = (index, e) =>
	{
		if (index >= 0)
		{
			const value = e.target.value;

			setTicketsList((state) =>
			{
				const ticketsListAr = [ ...state ];

				ticketsListAr[index].quantity = Number(value);

				return [ ...ticketsListAr ];

			});
		}
	};

	const handleChangeTicketTermQuantity = (index, e) =>
	{
		if (index >= 0)
		{
			const value = e.target.value;

			setTicketsListTerm((state) =>
			{
				const ticketsListAr = [ ...state ];

				ticketsListAr[index].quantity = Number(value);

				return [ ...ticketsListAr ];

			});
		}
	};

	const handleChangeTicketAfterUseQuantity = (index, e) =>
	{
		if (index >= 0)
		{
			const value = e.target.value;

			setTicketsListExpireAfterUse((state) =>
			{
				const ticketsListAr = [ ...state ];

				ticketsListAr[index].quantity = Number(value);

				return [ ...ticketsListAr ];

			});
		}
	};

	const showConfirmPage = () =>
	{
		if (!siteAdminLogin)
		{
			if (additionalEmail1 !== additionalEmail1Confirm)
			{
				setErrorMsg('追加メール送信先1が確認用の値と一致しません');

				return;
			}

			if (additionalEmail2 !== additionalEmail2Confirm)
			{
				setErrorMsg('追加メール送信先2が確認用の値と一致しません');

				return;
			}

			const emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;

			if (additionalEmail1 && !emailPattern.test(additionalEmail1))
			{
				setErrorMsg('追加メール送信先1が正しくありません');

				return;
			}

			if (additionalEmail2 && !emailPattern.test(additionalEmail2))
			{
				setErrorMsg('追加メール送信先2が正しくありません');

				return;
			}

			let amount = 0;

			let countList = 0;

			ticketsList.forEach((item) =>
			{
				amount += item.holesaleAmount * item.quantity;

				countList += item.quantity;
			});

			let countTerm = 0;

			ticketsListTerm.forEach((item) =>
			{
				amount += item.holesaleAmount * item.quantity;

				countTerm += item.quantity;
			});

			let countAfterUse = 0;

			ticketsListExpireAfterUse.forEach((item) =>
			{
				amount += item.holesaleAmount * item.quantity;

				countAfterUse += item.quantity;
			});

			if (countList + countTerm + countAfterUse > 0)
			{
				setErrorMsg('');

				setTotalAmount(amount);

				setTicketsListLen(countList);

				setTicketsListTermLen(countTerm);

				setTicketsListAfterUseLen(countAfterUse);

				setContent('confirm');
			}
			else
			{
				setErrorMsg('購入アイテムがありません');
			}
		}
	};

	const handlePurchase = () =>
	{
		if (!siteAdminLogin && serviceAccount.selfTicketPurchase)
		{
			const serviceTicketCreateAPI = functions.httpsCallable('serviceTicketCreate');

			const domain = getDomainWithoutSubdomain();

			setLoaging(true);

			serviceTicketCreateAPI({
				serviceAccountParamKey : serviceAccount.paramKey,
				tickets                : [
					...ticketsList,
					...ticketsListExpireAfterUse,
					...ticketsListTerm
				],
				email            : email,
				domain           : domain,
				additionalEmail1 : additionalEmail1,
				additionalEmail2 : additionalEmail2,
				buyer            : 'admin',
				salesChannel     : ISSUED_AT_ADMIN_PAGE,
				paymentMedhod    : PAYMENT_MONTHLY
			}).then((response) =>
			{
				if (response.data.tickets && response.data.tickets.length > 0)
				{
					setServiceUrl(`https://${domain}/service?id=${serviceAccount.paramKey}`);

					const ticketsTime = [];
					const ticketsAfterUse = [];
					const ticketsTerm = [];

					response.data.tickets.forEach((itm) =>
					{
						if (itm.type === 'time')
						{
							ticketsTime.push(itm);
						}
						else if (itm.type === 'afterUse')
						{
							ticketsAfterUse.push(itm);
						}
						else if (itm.type === 'term')
						{
							ticketsTerm.push(itm);
						}
					});

					setCreatedTicketsTime([ ...ticketsTime ]);
					setCreatedTicketsAfterUse([ ...ticketsAfterUse ]);
					setCreatedTicketsTerm([ ...ticketsTerm ]);
					setContent('complete');
					setLoaging(false);
					fetchTickets();
				}
				else
				{
					setErrorMsg('エラーが発生しました');
					setLoaging(false);
				}
			})
				.catch(() =>
				{
					setErrorMsg('エラーが発生しました');
					setLoaging(false);
				});
		}
	};

	return (
		<MuiThemeProvider theme={theme}>
			<Box className={classes.root}>
				{
					loading ?
						<Box className={classes.loading}>
							<CircularProgress />
						</Box>
						: content === 'complete' ?
							<Box className={classes.content}>
								<Box className={classes.pageHeader}>
									<Typography className={classes.title}>
										チケット購入完了
									</Typography>
								</Box>
								{ createdTicketsTime.length > 0 &&
								<Box className={classes.purchasedItemsTable}>
									<TableContainer component={Paper}>
										<Table className={classes.table} aria-label='simple table'>
											<TableHead>
												<TableRow>
													<TableCell align='center' className={classes.codeCell}>チケットコード</TableCell>
													<TableCell align='center' className={classes.expiryCell}>有効期限<br/>(購入後)</TableCell>
													<TableCell align='center' className={classes.timeCell}>通話時間</TableCell>
													<TableCell align='center' className={classes.priceCell}>卸売価格</TableCell>
												</TableRow>
											</TableHead>
											<TableBody>
												{createdTicketsTime.map((row, index) => (
													<TableRow key={index}>
														<TableCell align='center' className={classes.codeCell}>{serviceAccount.paramKey?.toUpperCase()}{row.ticketNumber}</TableCell>
														<TableCell align='center'>{row.expiryTerm?.value && row.expiryTerm.type ? showExpiryTerm(row.expiryTerm): '-'}</TableCell>
														<TableCell align='center' className={classes.timeCell}>{row.minutes === -1 ? '使い放題': `${row.minutes}分`}</TableCell>
														<TableCell align='center' className={classes.priceCell}>{showTaxIncludedAmount(row.holesaleAmount)}円(税込)</TableCell>
													</TableRow>
												))}
											</TableBody>
										</Table>
									</TableContainer>
								</Box>
								}

								{ createdTicketsAfterUse.length > 0 &&
								<Box className={classes.purchasedItemsTable}>
									<TableContainer component={Paper}>
										<Table className={classes.table} aria-label='simple table'>
											<TableHead>
												<TableRow>
													<TableCell align='center' className={classes.codeCell}>チケットコード</TableCell>
													<TableCell align='center' className={classes.expiryCell}>有効期限<br/>(コード入力後)</TableCell>
													<TableCell align='center' className={classes.timeCell}>通話時間</TableCell>
													<TableCell align='center' className={classes.priceCell}>卸売価格</TableCell>
												</TableRow>
											</TableHead>
											<TableBody>
												{createdTicketsAfterUse.map((row, index) => (
													<TableRow key={index}>
														<TableCell align='center' className={classes.codeCell}>{serviceAccount.paramKey?.toUpperCase()}{row.ticketNumber}</TableCell>
														<TableCell align='center' className={classes.expiryCell}>{row.expiryTermAfterUse?.value && row.expiryTermAfterUse.type ? showExpiryTerm(row.expiryTermAfterUse): '-'}</TableCell>
														<TableCell align='center' className={classes.timeCell}>{row.minutes === -1 ? '使い放題': `${row.minutes}分`}</TableCell>
														<TableCell align='center' className={classes.priceCell}>{showTaxIncludedAmount(row.holesaleAmount)}円(税込)</TableCell>
													</TableRow>
												))}
											</TableBody>
										</Table>
									</TableContainer>
								</Box>
								}

								{ createdTicketsTerm.length > 0 &&
								<Box className={classes.purchasedItemsTable}>
									<TableContainer component={Paper}>
										<Table className={classes.table} aria-label='simple table'>
											<TableHead>
												<TableRow>
													<TableCell align='center' className={classes.codeCell}>チケットコード</TableCell>
													<TableCell align='center' className={classes.expiryCell}>有効期限<br/>(購入後)</TableCell>
													<TableCell align='center' className={classes.timeCell}>通話時間</TableCell>
													<TableCell align='center' className={classes.priceCell}>卸売価格</TableCell>
												</TableRow>
											</TableHead>
											<TableBody>
												{createdTicketsTerm.map((row, index) => (
													<TableRow key={index}>
														<TableCell align='center' className={classes.codeCell}>{serviceAccount.paramKey?.toUpperCase()}{row.ticketNumber}</TableCell>
														<TableCell align='center' className={classes.expiryCell}>{row.expiryTerm?.value && row.expiryTerm?.type ? showExpiryTerm(row.expiryTerm): '-' }</TableCell>
														<TableCell align='center' className={classes.timeCell}>{row.minutes === -1 ? '使い放題': `${row.minutes}分`}</TableCell>
														<TableCell align='center' className={classes.priceCell}>{showTaxIncludedAmount(row.holesaleAmount)}円(税込)</TableCell>
													</TableRow>
												))}
											</TableBody>
										</Table>
									</TableContainer>
								</Box>
								}
								<Box className={`${classes.spaceLine} ${classes.totalAmountLine}`}>
									<Typography className={classes.inputTitle}>
										ご請求
									</Typography>
									<Box className={classes.totalAmountBox}>
										<Typography
											className={classes.totalAmountTitle}
										>
											合計金額
										</Typography>
										<Typography className={classes.totalAmountNumber}>
											{showTaxIncludedAmount(totalAmount)}円(税込)
										</Typography>
									</Box>
								</Box>
								<Box className={classes.paymentLine}>
									<Typography className={classes.totalAmountPayment}>
										{`${thisYear}年${thisMonth}月締め${nextMonthYear}年${nextMonth}月お支払い`}
									</Typography>
								</Box>

								<Box className={classes.linkToServiceBox}>
									<Typography className={classes.linkToServiceTitle}>
										通訳サービス利用ページ
									</Typography>
									<a
										href={serviceUrl}
										className={classes.linkToServiceURL}
										target='_blank'
										rel='noreferrer'
									>
										{serviceUrl}
									</a>

								</Box>
							</Box>
							: content === 'confirm' ?
								<Box className={classes.content}>
									<Box className={classes.pageHeader}>
										<Typography className={classes.title}>
											チケット購入
										</Typography>
									</Box>
									{ errorMsg &&
									<Typography className={classes.errorMsg}>
										{errorMsg}
									</Typography>
									}
									{ singleLineEmailInput ?
										<Box className={`${classes.spaceLineEmail} ${classes.firstEmail}`}>
											<Typography className={classes.inputTitle}>
												チケットコード送信メールアドレス
											</Typography>
											<Box className={classes.emailInputBox}>
												<input
													type='text'
													value={email}
													className={classes.emailInput}
													disabled
												/>
											</Box>
										</Box>
										:
										<Box className={`${classes.spaceLineEmailSplit} ${classes.firstEmail}`}>
											<Typography className={classes.inputTitleSplit}>
												チケットコード送信メールアドレス
											</Typography>
											<Box className={classes.emailInputBoxSplit}>
												<input
													type='text'
													value={email}
													className={classes.emailInput}
													disabled
												/>
											</Box>
										</Box>
									}
									{ additionalEmail1 &&
									<>
										{
											singleLineEmailInput ?
												<Box className={classes.spaceLineEmail}>
													<Typography className={classes.inputTitle}>
														追加メール送信先1(任意)
													</Typography>
													<Box className={classes.emailInputBox}>
														<input
															type='text'
															value={additionalEmail1}
															className={classes.emailInput}
															onChange={(e) => setAdditionalEmail1(e.target.value)}
															disabled
														/>

													</Box>
												</Box>
												:
												<Box
													className={classes.spaceLineEmailSplit}
												>
													<Typography className={classes.inputTitleSplit}>
														追加メール送信先1(任意)
													</Typography>
													<Box className={classes.emailInputBoxSplit}>
														<input
															type='text'
															value={additionalEmail1}
															className={classes.emailInput}
															onChange={(e) => setAdditionalEmail1(e.target.value)}
															disabled
														/>

													</Box>
												</Box>
										}
									</>
									}
									{ additionalEmail2 &&
									<>
										{
											singleLineEmailInput ?
												<Box
													className={
														classes.spaceLineEmail}
												>
													<Typography className={classes.inputTitle}>
														追加メール送信先2(任意)
													</Typography>
													<Box className={classes.emailInputBox}>
														<input
															type='text'
															value={additionalEmail2}
															className={classes.emailInput}
															onChange={(e) => setAdditionalEmail2(e.target.value)}
															disabled
														/>
													</Box>
												</Box>
												:
												<Box
													className={classes.spaceLineEmailSplit}
												>
													<Typography className={classes.inputTitleSplit}>
														追加メール送信先2(任意)
													</Typography>
													<Box className={classes.emailInputBoxSplit}>
														<input
															type='text'
															value={additionalEmail2}
															className={classes.emailInput}
															onChange={(e) => setAdditionalEmail2(e.target.value)}
															disabled
														/>
													</Box>
												</Box>
										}
									</>
									}
									<Box className={classes.ticketTitleLine}>
										<Typography className={classes.inputTitle}>購入チケット確認</Typography>
									</Box>
									<Box className={classes.singleLineTickets}>
										<Box className={classes.ticketColumnConfirm}>
											{ ticketsListLen > 0 ?
												<Box className={`${classes.ticketBox} ${classes.ticketBoxConfirm}`} >
													<Box className={`${classes.ticketLine} ${classes.ticketLineConfirm}`}>
														<Box
															className={`${classes.ticketItem} ${classes.ticketItemMinutesConfirm}`}
														>
															利用分数
														</Box>
														<Box
															className={`${classes.ticketItem} ${classes.ticketItemValidTimeConfirm}`}
														>
															有効期限<br/>(購入後)
														</Box>
														<Box
															className={`${classes.ticketItem} ${classes.ticketItemAmountConfirm}`}
														>
															料金(税込)
														</Box>

														<Box
															className={`${classes.ticketItem} ${classes.ticketItemQuantityConfirm}`}
														>
															数量
														</Box>
														<Box
															className={`${classes.ticketItem} ${classes.ticketItemTotalAmountConfirm}`}
														>
															金額
														</Box>
													</Box>
													{	ticketsList.map((item, index) =>
													{

														if (item.quantity > 0)
														{
															return (<Box className={`${classes.ticketLine} ${classes.ticketLineConfirm}`} key={index}>
																<Box
																	className={`${classes.ticketItem} ${classes.ticketItemMinutesConfirm}`}
																>
																	<Typography className={classes.ticketItemText}>
																		{item.minutes === -1 ? '使い放題': `${item.minutes}分` }
																	</Typography>
																</Box>
																<Box
																	className={`${classes.ticketItem} ${classes.ticketItemValidTimeConfirm}`}
																>
																	<Typography className={classes.ticketItemText}>
																		{showExpiryTerm(item.expiryTerm)}
																	</Typography>
																</Box>
																<Box
																	className={`${classes.ticketItem} ${classes.ticketItemAmountConfirm}`}
																>
																	<Typography className={classes.ticketItemText}>
																		{showTaxIncludedAmount(item.holesaleAmount)}円(税込)
																	</Typography>
																</Box>

																<Box
																	className={`${classes.ticketItem} ${classes.ticketItemQuantityConfirm}`}
																>
																	<Typography className={classes.ticketItemText}>
																		{item.quantity}
																	</Typography>
																</Box>
																<Box
																	className={classes.ticketItemTotalAmountConfirm}
																>
																	<Typography className={classes.ticketItemText}>
																		{showTaxIncludedAmount(
																			item.holesaleAmount*item.quantity)
																		}円(税込)
																	</Typography>
																</Box>
															</Box>
															);
														}
														else
														{
															return null;
														}
													})}
												</Box>
												: null }
											{ ticketsListAfterUseLen ?
												<Box className={`${classes.ticketBox} ${classes.ticketBoxConfirm}`} >
													<Box className={`${classes.ticketLine} ${classes.ticketLineConfirm}`}>
														<Box
															className={`${classes.ticketItem} ${classes.ticketItemMinutesConfirm}`}
														>
															利用分数
														</Box>
														<Box
															className={`${classes.ticketItem} ${classes.ticketItemValidTimeConfirm}`}
														>
															有効期限<br/>(コード入力後)
														</Box>
														<Box
															className={`${classes.ticketItem} ${classes.ticketItemAmountConfirm}`}
														>
															料金(税込)
														</Box>

														<Box
															className={`${classes.ticketItem} ${classes.ticketItemQuantityConfirm}`}
														>
															数量
														</Box>
														<Box
															className={`${classes.ticketItem} ${classes.ticketItemTotalAmountConfirm}`}
														>
															金額
														</Box>
													</Box>
													{	ticketsListExpireAfterUse.map((item, index) =>
													{
														if (item.quantity > 0)
														{
															return (<Box className={`${classes.ticketLine} ${classes.ticketLineConfirm}`} key={index}>
																<Box
																	className={`${classes.ticketItem} ${classes.ticketItemMinutesConfirm}`}
																>
																	<Typography className={classes.ticketItemText}>
																		{item.minutes === -1 ? '使い放題': `${item.minutes}分` }
																	</Typography>
																</Box>
																<Box
																	className={`${classes.ticketItem} ${classes.ticketItemValidTimeConfirm}`}
																>
																	<Typography className={classes.ticketItemText}>
																		{showExpiryTerm(item.expiryTermAfterUse)}
																	</Typography>
																</Box>
																<Box
																	className={`${classes.ticketItem} ${classes.ticketItemAmountConfirm}`}
																>
																	<Typography className={classes.ticketItemText}>
																		{showTaxIncludedAmount(item.holesaleAmount)}円(税込)
																	</Typography>
																</Box>

																<Box
																	className={`${classes.ticketItem} ${classes.ticketItemQuantityConfirm}`}
																>
																	<Typography className={classes.ticketItemText}>
																		{item.quantity}
																	</Typography>
																</Box>
																<Box
																	className={`${classes.ticketItem} ${classes.ticketItemTotalAmountConfirm}`}
																>
																	<Typography className={classes.ticketItemText}>
																		{showTaxIncludedAmount(
																			item.holesaleAmount*item.quantity)
																		}円(税込)
																	</Typography>
																</Box>
															</Box>
															);
														}
														else
														{
															return null;
														}
													})}
												</Box>
												: null }
											{ ticketsListTermLen > 0 ?
												<Box className={`${classes.ticketBox} ${classes.ticketBoxConfirm}`} >
													<Box className={`${classes.ticketLine} ${classes.ticketLineConfirm}`}>
														<Box
															className={`${classes.ticketItem} ${classes.ticketItemMinutesConfirm}`}
														>
															利用分数
														</Box>
														<Box
															className={`${classes.ticketItem} ${classes.ticketItemValidTimeConfirm}`}
														>
															有効期限<br/>(購入後)
														</Box>
														<Box
															className={`${classes.ticketItem} ${classes.ticketItemAmountConfirm}`}
														>
															料金(税込)
														</Box>

														<Box
															className={`${classes.ticketItem} ${classes.ticketItemQuantityConfirm}`}
														>
															数量
														</Box>
														<Box
															className={`${classes.ticketItem} ${classes.ticketItemTotalAmountConfirm}`}
														>
															金額
														</Box>
													</Box>
													{	ticketsListTerm.map((item, index) =>
													{
														if (item.quantity > 0)
														{
															return (
																<Box className={`${classes.ticketLine} ${classes.ticketLineConfirm}`} key={index}>
																	<Box
																		className={`${classes.ticketItem} ${classes.ticketItemMinutesConfirm}`}
																	>
																		<Typography className={classes.ticketItemText}>
																			{item.minutes === -1 ? '使い放題': `${item.minutes}分`}
																		</Typography>
																	</Box>
																	<Box
																		className={`${classes.ticketItem} ${classes.ticketItemValidTimeConfirm}`}
																	>
																		<Typography className={classes.ticketItemText}>
																			{showExpiryTerm(item.expiryTerm)}
																		</Typography>
																	</Box>
																	<Box
																		className={`${classes.ticketItem} ${classes.ticketItemAmountConfirm}`}
																	>
																		<Typography className={classes.ticketItemText}>
																			{showTaxIncludedAmount(item.holesaleAmount)}円(税込)
																		</Typography>
																	</Box>

																	<Box
																		className={`${classes.ticketItem} ${classes.ticketItemQuantityConfirm}`}
																	>
																		<Typography className={classes.ticketItemText}>
																			{item.quantity}
																		</Typography>
																	</Box>
																	<Box
																		className={`${classes.ticketItem} ${classes.ticketItemTotalAmountConfirm}`}
																	>
																		<Typography className={classes.ticketItemText}>
																			{showTaxIncludedAmount(
																				item.holesaleAmount*item.quantity
																			)}円(税込)
																		</Typography>
																	</Box>
																</Box>
															);
														}
														else
														{
															return null;
														}
													})}
												</Box>
												: null}
										</Box>
									</Box>
									<Box className={`${classes.spaceLine} ${classes.totalAmountLine}`}>
										<Typography className={classes.inputTitle}>
											ご請求
										</Typography>
										<Box className={classes.totalAmountBox}>
											<Typography
												className={classes.totalAmountTitle}
											>
												合計金額
											</Typography>
											<Typography className={classes.totalAmountNumber}>
												{showTaxIncludedAmount(totalAmount)}円(税込)
											</Typography>
										</Box>
									</Box>
									<Box className={classes.paymentLine}>
										<Typography className={classes.totalAmountPayment}>
											{`${thisYear}年${thisMonth}月締め${nextMonthYear}年${nextMonth}月お支払い`}
										</Typography>
									</Box>
									<Box className={`${classes.btnBox} ${classes.btnBoxConfirm}`}>
										<Button
											className={`${classes.btn} ${classes.backBtn}`}
											onClick={() => setContent('home')}
										>
											<FormattedMessage
												id='label.back'
												defaultMessage='back'
											/>
										</Button>
										<Box className={classes.confirmBtnBox}>
											<Button
												className={`${classes.btn} ${classes.actionBtn}`}
												onClick={handlePurchase}
											>
												<FormattedMessage
													id='label.purchase'
													defaultMessage='Purchase'
												/>
											</Button>
											<Typography className={classes.confirmBtnText}>
												購入ボタンを押すと確定します。
											</Typography>
										</Box>
									</Box>

								</Box>
								:
								<Box className={classes.content}>
									<Box className={classes.pageHeader}>
										<Typography className={classes.title}>
											チケット購入
										</Typography>
									</Box>
									{ errorMsg &&
									<Typography className={classes.errorMsg}>
										{errorMsg}
									</Typography>
									}
									{
										singleLineEmailInput ?
											<Box className={`${classes.spaceLineEmail} ${classes.firstEmail}`}>
												<Typography className={classes.inputTitle}>
													チケットコード送信メールアドレス
												</Typography>
												<Box className={classes.emailInputBox}>
													<input
														type='text'
														value={email}
														className={classes.emailInput}
														disabled
													/>
												</Box>
											</Box>
											:
											<Box className={`${classes.spaceLineEmailSplit} ${classes.firstEmail}`}>
												<Typography className={classes.inputTitleSplit}>
													チケットコード送信メールアドレス
												</Typography>
												<Box className={classes.emailInputBoxSplit}>
													<input
														type='text'
														value={email}
														className={classes.emailInput}
														disabled
													/>
												</Box>
											</Box>
									}
									{ singleLineEmailInput ?
										<>
											<Box className={classes.spaceLineEmail}>
												<Typography className={classes.inputTitle}>
													追加メール送信先1(任意)
												</Typography>
												<Box
													className={classes.additionalEmailInputBox}
												>
													<input
														type='text'
														value={additionalEmail1}
														className={`${classes.emailInput} ${classes.additionalEmailInput}`}
														onChange={(e) => setAdditionalEmail1(e.target.value)}
														placeholder='メールアドレス'
													/>
													<input
														type='text'
														value={additionalEmail1Confirm}
														className={`${classes.emailInput} ${classes.additionalEmailInput}`}
														onChange={(e) => setAdditionalEmail1Confirm(e.target.value)}
														placeholder='メールアドレス(確認)'
													/>
												</Box>
											</Box>
											<Box className={classes.spaceLineEmail}>
												<Typography className={classes.inputTitle}>
													追加メール送信先2(任意)
												</Typography>
												<Box
													className={classes.additionalEmailInputBox}
												>
													<input
														type='text'
														value={additionalEmail2}
														className={`${classes.emailInput} ${classes.additionalEmailInput}`}
														onChange={(e) => setAdditionalEmail2(e.target.value)}
														placeholder='メールアドレス'
													/>
													<input
														type='text'
														value={additionalEmail2Confirm}
														className={`${classes.emailInput} ${classes.additionalEmailInput}`}
														onChange={(e) => setAdditionalEmail2Confirm(e.target.value)}
														placeholder='メールアドレス(確認)'
													/>
												</Box>
											</Box>
										</>
										:
										<>
											<Box className={classes.spaceLineEmailSplit}>
												<Typography className={classes.inputTitleSplit}>
													追加メール送信先1(任意)
												</Typography>
												<Box
													className={classes.additionalEmailInputBoxSplit}
												>
													<input
														type='text'
														value={additionalEmail1}
														className={`${classes.emailInput} ${classes.additionalEmailInput}`}
														onChange={(e) => setAdditionalEmail1(e.target.value)}
														placeholder='メールアドレス'
													/>
													<input
														type='text'
														value={additionalEmail1Confirm}
														className={`${classes.emailInput} ${classes.additionalEmailInput}`}
														onChange={(e) => setAdditionalEmail1Confirm(e.target.value)}
														placeholder='メールアドレス(確認)'
													/>
												</Box>
											</Box>
											<Box className={classes.spaceLineEmailSplit}>
												<Typography className={classes.inputTitleSplit}>
													追加メール送信先2(任意)
												</Typography>
												<Box
													className={classes.additionalEmailInputBoxSplit}
												>
													<input
														type='text'
														value={additionalEmail2}
														className={`${classes.emailInput} ${classes.additionalEmailInput}`}
														onChange={(e) => setAdditionalEmail2(e.target.value)}
														placeholder='メールアドレス'
													/>
													<input
														type='text'
														value={additionalEmail2Confirm}
														className={`${classes.emailInput} ${classes.additionalEmailInput}`}
														onChange={(e) => setAdditionalEmail2Confirm(e.target.value)}
														placeholder='メールアドレス(確認)'
													/>
												</Box>
											</Box>
										</>
									}
									<Box className={classes.ticketTitleLine}>
										<Typography className={classes.inputTitle}>購入チケット選択</Typography>
									</Box>
									<Box className={
										singleLineTickets
											? classes.singleLineTickets
											: ''}
									>
										{ ticketsList.length > 0 &&
										<Box className={classes.ticketColumn}>
											<Typography className={classes.ticketBoxTitle}>
												購入後有効期限：{showExpiryTerm(serviceAccount.defaultExpiryTerm)}
											</Typography>
											<Box className={singleLineTickets ? `${classes.ticketBox} ${classes.ticketBoxTwo}` : `${classes.ticketBox} ${classes.ticketBoxOne}`} >
												<Box className={classes.ticketLine}>
													<Box
														className={`${classes.ticketItem} ${classes.ticketItemMinutes}`}
													>
														利用分数
													</Box>
													<Box
														className={`${classes.ticketItem} ${classes.ticketItemAmount}`}
													>
														料金(税込)
													</Box>

													<Box className={classes.ticketItemQuantity}>
														数量
													</Box>
												</Box>
												{	ticketsList.map((item, index) => (
													<Box className={classes.ticketLine} key={index}>
														<Box
															className={`${classes.ticketItem} ${classes.ticketItemMinutes}`}
														>
															<Typography className={classes.ticketItemText}>
																{item.minutes === -1 ? '使い放題': `${item.minutes}分` }
															</Typography>
														</Box>
														<Box
															className={`${classes.ticketItem} ${classes.ticketItemAmount}`}
														>
															<Typography className={classes.ticketItemText}>
																{showTaxIncludedAmount(item.holesaleAmount)}円(税込)
															</Typography>
														</Box>

														<Box className={classes.ticketItemQuantity}>
															<input
																id={`ticket-quantity-input-${index}`}
																onChange={(e) => handleChangeTicketQuantity(index, e)}
																value={item.quantity}
																className={classes.ticketQuantityInput}
																type='number'
																max={999}
																min={0}
															/>
														</Box>
													</Box>
												))}
											</Box>
										</Box>
										}

										{ ticketsListExpireAfterUse.length > 0 &&
										<Box className={classes.ticketColumn}>
											<Typography className={classes.ticketBoxTitle}>
												購入後有効期限：{showExpiryTerm(serviceAccount.defaultExpiryTermAfterUse)}
											</Typography>
											<Box className={singleLineTickets ? `${classes.ticketBox} ${classes.ticketBoxTwo}` : `${classes.ticketBox} ${classes.ticketBoxOne}`} >
												<Box className={classes.ticketLine}>
													<Box
														className={`${classes.ticketItem} ${classes.ticketItemMinutesAfterUse}`}
													>
														利用分数
													</Box>
													<Box
														className={`${classes.ticketItem} ${classes.ticketItemExpiry}`}
													>
														有効期限<br/>(コード入力後)
													</Box>
													<Box
														className={`${classes.ticketItem} ${classes.ticketItemAmountAfterUse}`}
													>
														料金(税込)
													</Box>

													<Box className={classes.ticketItemQuantity}>
														数量
													</Box>
												</Box>
												{	ticketsListExpireAfterUse.map((item, index) => (
													<Box className={classes.ticketLine} key={index}>
														<Box
															className={`${classes.ticketItem} ${classes.ticketItemMinutesAfterUse}`}
														>
															<Typography className={classes.ticketItemText}>
																{item.minutes === -1 ? '使い放題': `${item.minutes}分` }
															</Typography>
														</Box>
														<Box
															className={`${classes.ticketItem} ${classes.ticketItemExpiry}`}
														>
															{showExpiryTerm(item.expiryTermAfterUse)}
														</Box>
														<Box
															className={`${classes.ticketItem} ${classes.ticketItemAmountAfterUse}`}
														>
															<Typography className={classes.ticketItemText}>
																{showTaxIncludedAmount(item.holesaleAmount)}円(税込)
															</Typography>
														</Box>

														<Box className={classes.ticketItemQuantity}>
															<input
																id={`ticket-quantity-after-use-input-${index}`}
																onChange={
																	(e) => handleChangeTicketAfterUseQuantity(index, e)
																}
																value={item.quantity}
																className={classes.ticketQuantityInput}
																type='number'
																max={999}
																min={0}
															/>
														</Box>
													</Box>
												))}
											</Box>
										</Box>
										}
									</Box>

									{ ticketsListTerm.length > 0 &&
									<Box>
										<Box className={classes.ticketColumn}>
											<Typography className={classes.ticketBoxTitle}>
												購入後有効期限別
											</Typography>
											<Box className={singleLineTickets ? `${classes.ticketBox} ${classes.ticketBoxTwo}` : `${classes.ticketBox} ${classes.ticketBoxOne}`} >
												<Box className={classes.ticketLine}>
													<Box
														className={`${classes.ticketItem} ${classes.ticketItemMinutes}`}
													>
														利用期間(分数)
													</Box>
													<Box
														className={`${classes.ticketItem} ${classes.ticketItemAmount}`}
													>
														料金(税込)
													</Box>

													<Box className={classes.ticketItemQuantity}>
														数量
													</Box>
												</Box>
												{	ticketsListTerm.map((item, index) => (
													<Box className={classes.ticketLine} key={index}>
														<Box
															className={`${classes.ticketItem} ${classes.ticketItemMinutes}`}
														>
															<Typography className={classes.ticketItemText}>
																{showExpiryDays(item.expiryTerm)}({item.minutes === -1 ? '使い放題': `${item.minutes}分`})
															</Typography>
														</Box>
														<Box
															className={`${classes.ticketItem} ${classes.ticketItemAmount}`}
														>
															<Typography className={classes.ticketItemText}>
																{showTaxIncludedAmount(item.holesaleAmount)}円(税込)
															</Typography>
														</Box>

														<Box className={classes.ticketItemQuantity}>
															<input
																id={`ticket-quantity-term-input-${index}`}
																onChange={
																	(e) => handleChangeTicketTermQuantity(index, e)
																}
																value={item.quantity}
																className={classes.ticketQuantityInput}
																type='number'
																max={999}
																min={0}
															/>
														</Box>
													</Box>
												))}
											</Box>
										</Box>
									</Box>
									}

									<Box className={classes.btnBox}>
										<Button
											className={`${classes.btn} ${classes.actionBtn}`}
											onClick={showConfirmPage}
										>
											<FormattedMessage
												id='label.confirm'
												defaultMessage='Confirm'
											/>
										</Button>
									</Box>

								</Box>
				}
			</Box>
		</MuiThemeProvider>
	);
};

PurchaseTicket.propTypes =
{
	classes         : PropTypes.object.isRequired,
	serviceAccount  : PropTypes.object,
	priceLists      : PropTypes.array.isRequired,
	page            : PropTypes.string,
	fetchTickets    : PropTypes.func.isRequired,
	drawerWidthProp : PropTypes.number.isRequired,
	siteAdminLogin  : PropTypes.bool
};

const mapStateToProps = () =>
{
	return {
	};
};

const mapDispatchToProps = () =>
{
	return {
	};
};

export default withRoomContext(connect(
	mapStateToProps,
	mapDispatchToProps,
	null,
	{}
)(withStyles(styles)(PurchaseTicket)));